<template>
  <div
    :class="
      'card card-custom gutter-b card-stretch' +
        (!visibility ? ' bg-gray-200' : '')
    "
  >
    <div
      class="card-body justify-content-center d-flex align-items-start flex-column"
    >
      <!--begin::Icon-->
      <!--begin::Follower-->
      <div class="mx-auto">
        <span class="font-weight-bold font-size-h7 text-muted">
          {{ $t(`dashboard.status.${title}`) }}
        </span>
      </div>
      <div class="mx-auto mt-2">
        <VueSvgGauge
          class="wide-gauge"
          :start-angle="-110"
          :end-angle="110"
          :value="visibility && score !== false ? score : 0"
          :separator-step="1"
          :min="0"
          :max="6"
          :inner-radius="60"
          :gauge-color="[
            {
              offset: 0,
              color: getColorByValueAndBase(
                this.score ? this.score.toFixed(0) : 0,
                6,
                true
              )
            },
            {
              offset: 100,
              color: getColorByValueAndBase(
                this.score ? this.score.toFixed(0) + 1 : 0,
                6,
                true
              )
            }
          ]"
          :scale-interval="1"
        >
          <div class="inner-text inner-text--1">
            <span v-if="score >= 0 || score === false">
              {{ visibility && score !== false ? $n(score.toFixed(1)) : "-" }}
            </span>
            <span v-else>?</span>
            <span class="small">/6</span>
          </div>
        </VueSvgGauge>
      </div>
    </div>
  </div>
</template>

<script>
import { VueSvgGauge } from "vue-svg-gauge";
import { getColorByValueAndBase } from "@/utils/helpers";

export default {
  name: "MultiRiskGauge",
  components: {
    VueSvgGauge
  },
  props: {
    score: {
      required: true
    },
    title: {
      type: String,
      required: true
    },
    visibility: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    getColorByValueAndBase(value, base, hex) {
      return getColorByValueAndBase(value || 0, base, hex) + "aa";
    }
  }
};
</script>

<style lang="scss" scoped>
.wide-gauge.gauge {
  height: 90%;
  width: 90%;
  margin: auto;
}
</style>
