<template>
  <div v-if="multiriskDashboard">
    <b-row>
      <b-col
        v-for="punctuation in punctuations"
        :key="punctuation.title"
        cols-xl="6"
        cols-lg="6"
        cols-md="3"
        cols-sm="2"
      >
        <MultiRiskGauge
          :icon="punctuation.icon"
          :title="punctuation.title"
          :score="punctuation.punctuation"
          :iconClass="punctuation.iconClass"
          :visibility="punctuation.visibility"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="9" md="8" sm="12">
        <avg-max-risk
          :series="series"
          :discrete="discrete"
          :startDate="startDate"
          :base="6"
          :title="$t('menu.controller.header.multirisk')"
          :subtitle="$t('dashboard.multirisk.subtitle')"
        />
      </b-col>
      <b-col lg="3" md="4" sm="12">
        <KymatioScore
          :score="multiriskDashboard.score?.value || 0"
          :base="6"
          :title="$t('menu.controller.header.multirisk')"
        ></KymatioScore>
        <punctuation
          :icon="generalPunctuation.icon"
          :title="generalPunctuation.title"
          :punctuation="generalPunctuation.punctuation"
          :evolution="generalPunctuation.evolution"
          :percentage="generalPunctuation.percentage"
          :iconClass="generalPunctuation.iconClass"
          :visibility="generalPunctuation.visibility"
          :half="true"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import moment from "moment-timezone";

import AvgMaxRisk from "@/components/dashboard/AvgMaxRisk.component";
import MultiRiskGauge from "@/components/dashboard/MultiRiskGauge.component";
import Punctuation from "@/components/dashboard/Punctuation.component.vue";
import KymatioScore from "@/views/controller/dashboard/KymatioScore";

export default {
  name: "MultiRiskDashboard",
  components: {
    AvgMaxRisk,
    MultiRiskGauge,
    KymatioScore,
    Punctuation
  },
  computed: {
    ...mapGetters("Company", ["multiriskDashboard"]),
    ...mapGetters("Auth", ["hasModule"]),
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Controller", ["companySelected", "isCompanySelectedLoaded"]),
    punctuations() {
      return [
        {
          module: "awareness",
          icon: "shield-protected.svg",
          title: "cybersecurity",
          punctuation: this.multiriskDashboard.awareness.value,
          evolution: this.multiriskDashboard.awareness.evolution,
          percentage: false,
          iconClass: "tc3",
          visibility: this.hasModule(["awareness"])
        },
        {
          module: "phishing",
          icon: "fish.svg",
          title: "phishing",
          punctuation: this.multiriskDashboard.phishing?.value,
          evolution: this.multiriskDashboard.phishing?.evolution,
          percentage: false,
          iconClass: "tc5",
          visibility: this.hasModule(["phishing"])
        },
        {
          module: "abs",
          icon: "key.svg",
          title: "abs",
          punctuation: this.multiriskDashboard.abs.value,
          evolution: this.multiriskDashboard.abs.evolution,
          percentage: false,
          iconClass: "tc4",
          visibility: this.hasModule(["abs"])
        },
        {
          module: "wellbeing",
          icon: "home-heart.svg",
          title: "wellbeing",
          punctuation: this.multiriskDashboard.wellbeing.value,
          evolution: this.multiriskDashboard.wellbeing.evolution,
          percentage: false,
          iconClass: "tc2",
          visibility: this.hasModule(["wellbeing"])
        },
        {
          module: "burnout",
          icon: "fire.svg",
          title: "burnout",
          punctuation: this.multiriskDashboard.burnout.value,
          evolution: this.multiriskDashboard.burnout.evolution,
          percentage: false,
          iconClass: "tc7",
          visibility: this.hasModule(["burnout"])
        },
        {
          module: "human_risk",
          icon: "fire.svg",
          title: "human_risk",
          punctuation: this.multiriskDashboard.risk.value,
          evolution: this.multiriskDashboard.risk.evolution,
          percentage: false,
          iconClass: "tc7",
          visibility: this.hasModule(["risk"])
        }
      ];
    },
    generalPunctuation() {
      return {
        icon: "chain.svg",
        title: "progress",
        punctuation: this.multiriskDashboard.participation.value,
        evolution: this.multiriskDashboard.participation.evolution,
        percentage: false,
        iconClass: "tc6",
        visibility: true
      };
    },
    discrete() {
      return this.multiriskDashboard.score.evolution.discrete;
    },
    startDate() {
      let date = moment();
      _.each(this.series, element => {
        let first = _.first(_.orderBy(element.data, el => el[0], ["asc"]));
        first = first ? first[0] : false;

        if (first && moment(first).isBefore(moment(date))) {
          date = first;
        }
      });

      return date;
    },
    series() {
      return [
        {
          name: this.$t("menu.controller.header.multirisk"),
          type: "area",
          data: _.map(
            this.multiriskDashboard.score.evolution.series,
            element => [moment(element.date), element.value]
          ),
          color: "#0bb783"
        },
        {
          name: this.$t("dashboard.status.cybersecurity"),
          type: "line",
          service: "awareness",
          data: _.map(
            this.multiriskDashboard.awareness.evolution.series,
            element => [moment(element.date), element.value]
          ),
          color: "#d23333"
        },
        {
          name: this.$t("dashboard.status.phishing"),
          type: "line",
          service: "phishing",
          data: _.map(
            this.multiriskDashboard.phishing.evolution.series,
            element => [moment(element.date), element.value]
          ),
          color: "#ffeb3b"
        },
        {
          name: this.$t("dashboard.status.abs"),
          type: "line",
          service: "abs",
          data: _.map(this.multiriskDashboard.abs.evolution.series, element => [
            moment(element.date),
            element.value
          ]),
          color: "#ea48e6"
        },
        {
          name: this.$t("dashboard.status.wellbeing"),
          type: "line",
          service: "wellbeing",
          data: _.map(
            this.multiriskDashboard.wellbeing.evolution.series,
            element => [moment(element.date), element.value]
          ),
          color: "#3f51b5"
        },
        {
          name: this.$t("dashboard.status.burnout"),
          type: "line",
          service: "burnout",
          data: _.map(
            this.multiriskDashboard.burnout.evolution.series,
            element => [moment(element.date), element.value]
          ),
          color: "#ff9d00"
        },
        {
          name: this.$t("dashboard.status.human_risk"),
          type: "line",
          service: "risk",
          data: _.map(
            this.multiriskDashboard.risk.evolution.series,
            element => [moment(element.date), element.value]
          ),
          color: "#00CDFF"
        }
      ];
    }
  },
  methods: {
    ...mapActions("Company", ["loadMultiriskDashboard"])
  },
  mounted() {
    if (this.isConstantsActive) this.loadMultiriskDashboard();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadMultiriskDashboard();
    }
  }
};
</script>
