var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:'card card-custom gutter-b card-stretch' +
      (!_vm.visibility ? ' bg-gray-200' : '')},[_c('div',{staticClass:"card-body justify-content-center d-flex align-items-start flex-column"},[_c('div',{staticClass:"mx-auto"},[_c('span',{staticClass:"font-weight-bold font-size-h7 text-muted"},[_vm._v(" "+_vm._s(_vm.$t(`dashboard.status.${_vm.title}`))+" ")])]),_c('div',{staticClass:"mx-auto mt-2"},[_c('VueSvgGauge',{staticClass:"wide-gauge",attrs:{"start-angle":-110,"end-angle":110,"value":_vm.visibility && _vm.score !== false ? _vm.score : 0,"separator-step":1,"min":0,"max":6,"inner-radius":60,"gauge-color":[
          {
            offset: 0,
            color: _vm.getColorByValueAndBase(
              this.score ? this.score.toFixed(0) : 0,
              6,
              true
            )
          },
          {
            offset: 100,
            color: _vm.getColorByValueAndBase(
              this.score ? this.score.toFixed(0) + 1 : 0,
              6,
              true
            )
          }
        ],"scale-interval":1}},[_c('div',{staticClass:"inner-text inner-text--1"},[(_vm.score >= 0 || _vm.score === false)?_c('span',[_vm._v(" "+_vm._s(_vm.visibility && _vm.score !== false ? _vm.$n(_vm.score.toFixed(1)) : "-")+" ")]):_c('span',[_vm._v("?")]),_c('span',{staticClass:"small"},[_vm._v("/6")])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }